
/////////////// 共通設定 ///////////////////////////
body {
  padding: 0;
  margin: 0;
  overscroll-behavior: none;
  color: #000000;
  font-family: source-han-sans-japanese,
    sans-serif;
  font-weight: 200;
  font-style: normal;
  background: #F5F4F2;
  @media screen and (max-width: 320px) {
    font-size: 14px;
  }
}
main {
  min-height: 100%;
  max-width: 100%;
}
img {
  max-width: 100%;
  border: 0;
  vertical-align: bottom;
}

li {
  list-style: none;
}
ul {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  display: inline-block;
  color: #fff;
}

.container {
  width: 80%;
  margin: 0 auto;
  @media screen and (max-width: 1024px) {
      width: 90%;
  }
  @media screen and (max-width: 640px) {
    width: 90%;
  }
}
/////////////// カラー ///////////////////////////
$color-primary: #0a1123;
$color-secondary: #5d9b2d18;
$color-tertiary: #3d691b;
$color-four: lightgreen;

// 中央寄せ X
@mixin center-h {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
// 上下中央寄せ
@mixin center-xy {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@mixin clearfix {
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}
// ボーダー
@mixin border {
  border: #000000 solid 2px;
  border-radius: 5px;
}

// セクションのタイトル
.section-title {
  color: #fff;
  margin: 5.5em 0 3em 0;
  display: flex;
  align-items: center;
  border-left: $color-tertiary 7px solid;
  border-bottom: $color-tertiary 1px solid;
  border-radius: 3px;
  background-color: seagreen;
  
  @media screen and (max-width: 640px) {
    margin: 2em 0 1em 0;
  }
  
  img {
    margin-right: .8em;
    margin-left: 1em;
    width: 50px;
    height: 50px;
  }
}

@mixin inner-background {
  background-color: $color-secondary;
}

// ボタン
@mixin button {
  height: 3em;
  background: $color-tertiary;
  color: #fff;
  font-size: 1em;
  font-weight: bold;
  border-radius: 8px;
  margin: 0 auto;
  display: block;
}
.is-align-right {
  text-align: right;
}
/////////////// アニメーション ///////////////////////////
@keyframes menu-drop {
  0% {top: 46px};
  0% {top: 0};
}
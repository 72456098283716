@charset "UTF-8";

/////////////// アバウト ///////////////////////////

.about-inner {
  position: relative;
  padding: 1em;
  height: auto;
  // タブレット用
  @media screen and (max-width: 1024px) {
      margin: 9em 0;
  }
  // スマホ用
  @media screen and (max-width: 640px) {
    margin:0;
    height: 55%;
    padding: 1em 0;
  }
  h4 {
    font-size: 1.8em;
    @media screen and (max-width: 640px) {
      font-size: 1.2em;
    }
  }
  .about-map,
  .about-desc {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .about-desc {
    width: 100%;
    font-size: 1.8em;
    z-index: 1;
    text-align: center;
    @media screen and (max-width: 640px) {
        font-size: 1.2em;
    }
  }
 
  .about-hanten,
  .about-trees {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
      justify-content: space-between;
      margin: 1em;
      padding: 0;
    }
     > img {
       width: 20%;
       margin: 2em 0;
       height: auto;
       object-fit: contain;
       @media screen and (max-width: 640px) {
         width: 40%;
         height: 40%;
       }
    }
    h4 {
      font-size: 1.8em;
      align-items: center;
      @media screen and (max-width: 640px) {
          font-size: 1.2em;
      }
      .check-icon {
        width: 20px;
        height: 27px;
        margin: 0 .4em;
      }
      .underline {
        display: inline;
        background-image: linear-gradient(transparent 60%, rgb(252, 192, 185) 40%);
      }
    }
  }
  .about-hanten,
  .about-trees {
    @media screen and (max-width: 640px) {
      height: 50%;
      margin: 0;
      margin: 1em 0 1em 0;
    }
  }
  .about-hanten {
    position: relative;
    top: 0;
    z-index: 2;
    justify-content: flex-start;
    h4 {
      margin-left: 10%;
    }
    @media screen and (max-width: 640px) {
      padding-right: 0%;
      margin: 0;
    }
  }
  .about-trees {
    position: relative;
    right: 0;
    bottom: -5em;
    z-index: 2;
    justify-content: flex-end;
    h4 {
      margin-right: 10%;
    }
    @media screen and (max-width: 640px) {
      bottom: 0;
      margin-top: 3em;
      padding-left: 0%;
    }
  }
}
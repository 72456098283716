@charset "UTF-8";

/////////////// ニューズ ///////////////////////////
.news {
  h2 {
    align-items: center;
    margin-right: 1em;
  }
  #news-title {
    a {
      color:#000000;
    }
  }
  .news-inner {
    padding: .3em;
    @include border;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .news-content {
      box-sizing: border-box;
      background: #ffffff;
      padding: .3em;
      display: flex;
      width: 25%;
      height: auto;
      align-items: center;
      @media screen and (max-width: 1024px) {
        font-size: .9em;
        width: 25%;
      }
      @media screen and (max-width: 768px) {
          width: 49.9%;
          font-size: 1em;
      }
      @media screen and (max-width: 640px) {
          width: 100%;
      }
      .news-img {
        width: 40%;
        img {
          max-width: 110px;
          max-height: 80px;
          object-fit: cover;
        }
      }
      .news-title {
        height: 60px;
        box-sizing: border-box;
        font-size: .8em;
        margin: 0 auto 0 1em;
        width: 100%;
        @media screen and (max-width: 640px) {
          width: 50%;
        }
        a {
          color: skyblue;
          text-decoration: underline;
        }
        .post-date {
          font-weight: 400;
        }
        .post-cate {
          display: flex;
          align-items: center;
          i {
            margin-right: .2em;
          }
        }
        .post-title {
          width: 100%;
              h3 {
                font-size: .9em;
                margin: 0;
                padding: 0;
              }
        }
      }
    }
  }
  .news-content:not(:last-child) {
    @media screen and (max-width: 1024px) {
        border:none;
    }
    border-right: #000000 1px dashed;
    @media screen and (max-width: 640px) {
      border-bottom: #000000 1px dashed;
    }
  }
  .news-link {
    padding: 1em 0;
  }
}
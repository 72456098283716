@charset "UFT-8";

/////////////// サービス ///////////////////////////
.services-inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  // ここからモバイル
  @media screen and (max-width: 640px) {
    display: block;
  }

  .service {
    box-sizing: border-box;
    width: 24%;
    height: auto;
    @include border;
    padding: 1em;
    background:#fff;
    @include border();
    @media screen and (max-width: 1024px) {
        width: 50%;
        height: auto;
    }

    // ここからモバイル
    @media screen and (max-width: 640px) {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 1em;
      }
    }

    .service-pic {
      display: none;
    }

    article {
      margin-bottom: .5em;
      border-radius: 3px;
    }

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      border: 1px transparent solid;
      border-radius: 5px;
    }
    p {
      background: #fff;
    }
  }
}
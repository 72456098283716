/////////////// フォーム ///////////////////////////

.contact {
  .contact-inner {
    background: #fff;
    border-radius: 3px;
    padding: 2em 0;
    margin: 0 auto;

    .form-wrapper {
      width: 40%;
      margin: 0 auto;
      @media screen and (max-width: 768px) {
          width: 55%;
      }
      @media screen and (max-width: 640px) {
        width: 100%;
        box-sizing: border-box;
        padding: 1em;
      }

      form {
        margin: 0;

        input {
          box-sizing: border-box;
          padding: .5em;
        }

        input,
        textarea {
          border: 1px lightgrey solid;
          border-radius: 3px;
          font-size: 1em;
          width: 100%;
          margin-bottom: 1em;
        }

        .button {
          width: 50%;
          @include button();
          background: rgb(165, 165, 165);
          @media screen and (max-width: 640px) {
            width: 100%;
          }
        }
      }
    }
  }
}
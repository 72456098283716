/////////////// メインビジュアル ///////////////////////////
.main-visual {
  position: relative;
  display: block;
  color: #fff;
  .top-img {
    width: 100vw;
    height: auto;
  }
  // メインジュアルインナー
  .main-visual-inner {
    position: relative;
    box-sizing: border-box;
    @include center-xy();
    top: 44%;
    @media screen and (max-width: 640px) {
        top: 44%;
    }
    @media screen and (max-width:480px) {
      top: 46%;
    }

    transform: translate(-50%, -50%);
    h1 {
      text-align: center;
      font-size: 1.8em;
      @media screen and (max-width: 640px) {
        text-align: center;
        font-size: 1.4em;
        margin: 2em 0 1em 0;
      }
      @media screen and (max-width:480px) {
        margin-top: 1em;
        line-height: 1.4em;
      }
    }
    h2 {
      width: 100%;
      text-align: center;
      margin: 1em 0 1em 0;
      @media screen and (max-width: 1024px) {
          margin-top: .5em;
      }
      @media screen and (max-width: 768px) {
          margin-top: 0em;
      }
      @media screen and (max-width: 640px) {
        margin: 1em 0 1em 0;
        font-size: 1.2em;
      }
    }
    h3 {
      @media screen and (max-width: 640px) {
          font-size: 1.1em;
      }
    }
    p {
      text-align: center;
      font-size: 1.5em;
      font-weight: bold;
      .fa-phone {
        font-size: 30px;
        color: #fff;
        margin-right: .5em;
      }
      @media screen and (max-width:480px) {
        margin: .5em;
      }
    }
    .tel {
      font-size: 1.7em;
    }
    // img {
    //   position: relative;
    //   top: 50%;
    //   left: 50%;
    //   transform: translate(-50%, -50%);
    //   width: 40%;
      
    //   @media screen and (max-width: 640px) {
    //     width: 70%;
    //   }
    // }
    .contact-button {
      position: relative;
      box-sizing: border-box;
      color: #fff;
      border: 3px #fff solid;
      border-radius: 3px;
      text-align: center;
      @include center-h();
      width: 50%;
      padding: .8em;
      font-weight: bold;
      @media screen and (max-width: 640px) {
        font-size: 1.2em;
        width: 100%;
      }
      // @media screen and (max-width:480px) {
      //   bottom: -20%;
      // }
    }
  }
}
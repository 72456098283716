@charset "UTF-8";

/////////////// ヘッダー ///////////////////////////
header {
  position: fixed;
  height: 50px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  color:#fff;
  background: rgba(0, 0, 0, 0.4);

  .header-info {
    width: 90%;
    height: 30px;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
      display: none;
    }

    // ヘッダーインフォ
    .header-info-left,
    .header-info-right {
      font-size: 1.1em;
      font-weight: bold;
      vertical-align: middle;
      line-height: 30px;
      align-items: center;
    }

    .fa-envelope,
    .fa-phone {
      font-size: 1.2em;
      width: 25px;
      height: 30px;
      line-height: 30px;
    }

    .email {
      line-height: 30px;
      margin-right: .8em;
    }
  }

  .header-logo {
    @include center-h;
    top: 20px;
    width: 280px;
    height: auto;
    z-index: 2;

    @media screen and (max-width: 768px) {
      width: 200px;
      height: auto;
      top: 3px;
    }
  }

  .nav-wrapper {
    @include center-h;
    position: absolute;
    top: 30px;
    width: 100%;
    height:48px;
    margin:0;
    padding:0;
    background-color:#0a1123;
    margin: 0 auto;
    display: flex;
    background: $color-primary;
    justify-content: space-around;

    @media screen and (max-width: 1024px) {
      display: flex;
      justify-content: flex-end;
      top: 0;
    }
    // &:after {
    //   content: '';
    //   width: 100%;
    //   display: block;
    //   clear: both;
    // }
  }
  // PC用ナビ
  .nav-left,
  .nav-right {
    @media screen and (max-width:1024px) {
      display: none;
    }
  }
  .nav {
    ul {
      margin: 0;
      padding: 0;
    }

    li {
      font-weight: bold;
      padding: 0;
      margin: 1em;
      line-height: 50px;
      display: inline;
    }
  }

  // ハンバーガーメニューボタン
  .menu-button-open {
    position: absolute;
    right: 10px;
    padding: 0;
    width: auto;
    height: 48px;
    margin: 9px 0 7px 0;
    font-size: 30px;
    display: none;
    text-align: center;
    z-index: 2;

    @media screen and (max-width: 1024px) {
      display: block;
    }
  }
  // モバイルメニュー
  .mobile-nav {
    position: relative;
    transition: .7s;
    a {
      color: #fff;
    }
    @media screen and (max-width: 1024px) {
      nav {
        position: absolute;
        width: 100%;
        top: 12px;
        @media screen and (max-width: 768px) {
          top: 48px;
        }
        padding: 1.3em 0;
        background: rgba(50, 158, 97, 1);
        ul {
          width: 100%;
          display: flex;
          margin: 0;
          padding: 0;
          li {
            font-size: .3em;
            display: flex;
            flex-direction: column;
            text-align: center;
            width: 20%;
            img {
              display: block;
              margin: 0 auto;
              width: 40px;
              height: 40px;
              margin-bottom: .3em;
            }
          }
        }
      }
    }
  }
}

@charset "UTF-8";
/*! =========================================================================
CSSファイルはSassから生成してるので、CSSファイルからの変更はしないでください。
========================================================================== */
body {
  padding: 0;
  margin: 0;
  overscroll-behavior: none;
  color: #000000;
  font-family: source-han-sans-japanese, sans-serif;
  font-weight: 200;
  font-style: normal;
  background: #F5F4F2;
}

@media screen and (max-width: 320px) {
  body {
    font-size: 14px;
  }
}

main {
  min-height: 100%;
  max-width: 100%;
}

img {
  max-width: 100%;
  border: 0;
  vertical-align: bottom;
}

li {
  list-style: none;
}

ul {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  display: inline-block;
  color: #fff;
}

.container {
  width: 80%;
  margin: 0 auto;
}

@media screen and (max-width: 1024px) {
  .container {
    width: 90%;
  }
}

@media screen and (max-width: 640px) {
  .container {
    width: 90%;
  }
}

.section-title {
  color: #fff;
  margin: 5.5em 0 3em 0;
  display: flex;
  align-items: center;
  border-left: #3d691b 7px solid;
  border-bottom: #3d691b 1px solid;
  border-radius: 3px;
  background-color: seagreen;
}

@media screen and (max-width: 640px) {
  .section-title {
    margin: 2em 0 1em 0;
  }
}

.section-title img {
  margin-right: .8em;
  margin-left: 1em;
  width: 50px;
  height: 50px;
}

.is-align-right {
  text-align: right;
}

@keyframes menu-drop {
  0% {
    top: 46px;
  }
  0% {
    top: 0;
  }
}

header {
  position: fixed;
  height: 50px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  color: #fff;
  background: rgba(0, 0, 0, 0.4);
}

header .header-info {
  width: 90%;
  height: 30px;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  header .header-info {
    display: none;
  }
}

header .header-info .header-info-left,
header .header-info .header-info-right {
  font-size: 1.1em;
  font-weight: bold;
  vertical-align: middle;
  line-height: 30px;
  align-items: center;
}

header .header-info .fa-envelope,
header .header-info .fa-phone {
  font-size: 1.2em;
  width: 25px;
  height: 30px;
  line-height: 30px;
}

header .header-info .email {
  line-height: 30px;
  margin-right: .8em;
}

header .header-logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 20px;
  width: 280px;
  height: auto;
  z-index: 2;
}

@media screen and (max-width: 768px) {
  header .header-logo {
    width: 200px;
    height: auto;
    top: 3px;
  }
}

header .nav-wrapper {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  top: 30px;
  width: 100%;
  height: 48px;
  margin: 0;
  padding: 0;
  background-color: #0a1123;
  margin: 0 auto;
  display: flex;
  background: #0a1123;
  justify-content: space-around;
}

@media screen and (max-width: 1024px) {
  header .nav-wrapper {
    display: flex;
    justify-content: flex-end;
    top: 0;
  }
}

@media screen and (max-width: 1024px) {
  header .nav-left,
  header .nav-right {
    display: none;
  }
}

header .nav ul {
  margin: 0;
  padding: 0;
}

header .nav li {
  font-weight: bold;
  padding: 0;
  margin: 1em;
  line-height: 50px;
  display: inline;
}

header .menu-button-open {
  position: absolute;
  right: 10px;
  padding: 0;
  width: auto;
  height: 48px;
  margin: 9px 0 7px 0;
  font-size: 30px;
  display: none;
  text-align: center;
  z-index: 2;
}

@media screen and (max-width: 1024px) {
  header .menu-button-open {
    display: block;
  }
}

header .mobile-nav {
  position: relative;
  transition: .7s;
}

header .mobile-nav a {
  color: #fff;
}

@media screen and (max-width: 1024px) {
  header .mobile-nav nav {
    position: absolute;
    width: 100%;
    top: 12px;
    padding: 1.3em 0;
    background: #329e61;
  }
}

@media screen and (max-width: 1024px) and (max-width: 768px) {
  header .mobile-nav nav {
    top: 48px;
  }
}

@media screen and (max-width: 1024px) {
  header .mobile-nav nav ul {
    width: 100%;
    display: flex;
    margin: 0;
    padding: 0;
  }
  header .mobile-nav nav ul li {
    font-size: .3em;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 20%;
  }
  header .mobile-nav nav ul li img {
    display: block;
    margin: 0 auto;
    width: 40px;
    height: 40px;
    margin-bottom: .3em;
  }
}

.main-visual {
  position: relative;
  display: block;
  color: #fff;
}

.main-visual .top-img {
  width: 100vw;
  height: auto;
}

.main-visual .main-visual-inner {
  position: relative;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 44%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 640px) {
  .main-visual .main-visual-inner {
    top: 44%;
  }
}

@media screen and (max-width: 480px) {
  .main-visual .main-visual-inner {
    top: 46%;
  }
}

.main-visual .main-visual-inner h1 {
  text-align: center;
  font-size: 1.8em;
}

@media screen and (max-width: 640px) {
  .main-visual .main-visual-inner h1 {
    text-align: center;
    font-size: 1.4em;
    margin: 2em 0 1em 0;
  }
}

@media screen and (max-width: 480px) {
  .main-visual .main-visual-inner h1 {
    margin-top: 1em;
    line-height: 1.4em;
  }
}

.main-visual .main-visual-inner h2 {
  width: 100%;
  text-align: center;
  margin: 1em 0 1em 0;
}

@media screen and (max-width: 1024px) {
  .main-visual .main-visual-inner h2 {
    margin-top: .5em;
  }
}

@media screen and (max-width: 768px) {
  .main-visual .main-visual-inner h2 {
    margin-top: 0em;
  }
}

@media screen and (max-width: 640px) {
  .main-visual .main-visual-inner h2 {
    margin: 1em 0 1em 0;
    font-size: 1.2em;
  }
}

@media screen and (max-width: 640px) {
  .main-visual .main-visual-inner h3 {
    font-size: 1.1em;
  }
}

.main-visual .main-visual-inner p {
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;
}

.main-visual .main-visual-inner p .fa-phone {
  font-size: 30px;
  color: #fff;
  margin-right: .5em;
}

@media screen and (max-width: 480px) {
  .main-visual .main-visual-inner p {
    margin: .5em;
  }
}

.main-visual .main-visual-inner .tel {
  font-size: 1.7em;
}

.main-visual .main-visual-inner .contact-button {
  position: relative;
  box-sizing: border-box;
  color: #fff;
  border: 3px #fff solid;
  border-radius: 3px;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  padding: .8em;
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .main-visual .main-visual-inner .contact-button {
    font-size: 1.2em;
    width: 100%;
  }
}

.price .price-inner {
  width: 100%;
}

.price .price-inner > h3 {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  line-height: 1.5em;
  padding: .5em;
  margin: 0 0 1 0;
  border-radius: 3px;
  align-items: center;
  background: lightgreen;
}

.price .price-inner > h3 .fa-color {
  color: #000000;
}

.price .price-inner .price-tbl {
  width: 100%;
  margin: 1em 0;
  border-collapse: collapse;
  display: none;
}

.price .price-inner .price-tbl.open {
  display: inline-table;
}

.price .price-inner .price-tbl th,
.price .price-inner .price-tbl td {
  border: 1px solid #000000;
  padding: .5em;
  text-align: left;
}

@media screen and (max-width: 320px) {
  .price .price-inner .price-tbl th,
  .price .price-inner .price-tbl td {
    font-size: .7em;
  }
}

.price .price-inner .price-tbl td {
  background: #fff;
}

.price .price-inner .price-tbl th {
  background: rgba(240, 240, 240, 0.863);
}

.services-inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (max-width: 640px) {
  .services-inner {
    display: block;
  }
}

.services-inner .service {
  box-sizing: border-box;
  width: 24%;
  height: auto;
  border: #000000 solid 2px;
  border-radius: 5px;
  padding: 1em;
  background: #fff;
  border: #000000 solid 2px;
  border-radius: 5px;
}

@media screen and (max-width: 1024px) {
  .services-inner .service {
    width: 50%;
    height: auto;
  }
}

@media screen and (max-width: 640px) {
  .services-inner .service {
    width: 100%;
  }
  .services-inner .service:not(:last-child) {
    margin-bottom: 1em;
  }
}

.services-inner .service .service-pic {
  display: none;
}

.services-inner .service article {
  margin-bottom: .5em;
  border-radius: 3px;
}

.services-inner .service img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border: 1px transparent solid;
  border-radius: 5px;
}

.services-inner .service p {
  background: #fff;
}

.about-inner {
  position: relative;
  padding: 1em;
  height: auto;
}

@media screen and (max-width: 1024px) {
  .about-inner {
    margin: 9em 0;
  }
}

@media screen and (max-width: 640px) {
  .about-inner {
    margin: 0;
    height: 55%;
    padding: 1em 0;
  }
}

.about-inner h4 {
  font-size: 1.8em;
}

@media screen and (max-width: 640px) {
  .about-inner h4 {
    font-size: 1.2em;
  }
}

.about-inner .about-map,
.about-inner .about-desc {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.about-inner .about-desc {
  width: 100%;
  font-size: 1.8em;
  z-index: 1;
  text-align: center;
}

@media screen and (max-width: 640px) {
  .about-inner .about-desc {
    font-size: 1.2em;
  }
}

.about-inner .about-hanten,
.about-inner .about-trees {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .about-inner .about-hanten,
  .about-inner .about-trees {
    justify-content: space-between;
    margin: 1em;
    padding: 0;
  }
}

.about-inner .about-hanten > img,
.about-inner .about-trees > img {
  width: 20%;
  margin: 2em 0;
  height: auto;
  object-fit: contain;
}

@media screen and (max-width: 640px) {
  .about-inner .about-hanten > img,
  .about-inner .about-trees > img {
    width: 40%;
    height: 40%;
  }
}

.about-inner .about-hanten h4,
.about-inner .about-trees h4 {
  font-size: 1.8em;
  align-items: center;
}

@media screen and (max-width: 640px) {
  .about-inner .about-hanten h4,
  .about-inner .about-trees h4 {
    font-size: 1.2em;
  }
}

.about-inner .about-hanten h4 .check-icon,
.about-inner .about-trees h4 .check-icon {
  width: 20px;
  height: 27px;
  margin: 0 .4em;
}

.about-inner .about-hanten h4 .underline,
.about-inner .about-trees h4 .underline {
  display: inline;
  background-image: linear-gradient(transparent 60%, #fcc0b9 40%);
}

@media screen and (max-width: 640px) {
  .about-inner .about-hanten,
  .about-inner .about-trees {
    height: 50%;
    margin: 0;
    margin: 1em 0 1em 0;
  }
}

.about-inner .about-hanten {
  position: relative;
  top: 0;
  z-index: 2;
  justify-content: flex-start;
}

.about-inner .about-hanten h4 {
  margin-left: 10%;
}

@media screen and (max-width: 640px) {
  .about-inner .about-hanten {
    padding-right: 0%;
    margin: 0;
  }
}

.about-inner .about-trees {
  position: relative;
  right: 0;
  bottom: -5em;
  z-index: 2;
  justify-content: flex-end;
}

.about-inner .about-trees h4 {
  margin-right: 10%;
}

@media screen and (max-width: 640px) {
  .about-inner .about-trees {
    bottom: 0;
    margin-top: 3em;
    padding-left: 0%;
  }
}

.news h2 {
  align-items: center;
  margin-right: 1em;
}

.news #news-title a {
  color: #000000;
}

.news .news-inner {
  padding: .3em;
  border: #000000 solid 2px;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.news .news-inner .news-content {
  box-sizing: border-box;
  background: #ffffff;
  padding: .3em;
  display: flex;
  width: 25%;
  height: auto;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .news .news-inner .news-content {
    font-size: .9em;
    width: 25%;
  }
}

@media screen and (max-width: 768px) {
  .news .news-inner .news-content {
    width: 49.9%;
    font-size: 1em;
  }
}

@media screen and (max-width: 640px) {
  .news .news-inner .news-content {
    width: 100%;
  }
}

.news .news-inner .news-content .news-img {
  width: 40%;
}

.news .news-inner .news-content .news-img img {
  max-width: 110px;
  max-height: 80px;
  object-fit: cover;
}

.news .news-inner .news-content .news-title {
  height: 60px;
  box-sizing: border-box;
  font-size: .8em;
  margin: 0 auto 0 1em;
  width: 100%;
}

@media screen and (max-width: 640px) {
  .news .news-inner .news-content .news-title {
    width: 50%;
  }
}

.news .news-inner .news-content .news-title a {
  color: skyblue;
  text-decoration: underline;
}

.news .news-inner .news-content .news-title .post-date {
  font-weight: 400;
}

.news .news-inner .news-content .news-title .post-cate {
  display: flex;
  align-items: center;
}

.news .news-inner .news-content .news-title .post-cate i {
  margin-right: .2em;
}

.news .news-inner .news-content .news-title .post-title {
  width: 100%;
}

.news .news-inner .news-content .news-title .post-title h3 {
  font-size: .9em;
  margin: 0;
  padding: 0;
}

.news .news-content:not(:last-child) {
  border-right: #000000 1px dashed;
}

@media screen and (max-width: 1024px) {
  .news .news-content:not(:last-child) {
    border: none;
  }
}

@media screen and (max-width: 640px) {
  .news .news-content:not(:last-child) {
    border-bottom: #000000 1px dashed;
  }
}

.news .news-link {
  padding: 1em 0;
}

.contact .contact-inner {
  background: #fff;
  border-radius: 3px;
  padding: 2em 0;
  margin: 0 auto;
}

.contact .contact-inner .form-wrapper {
  width: 40%;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .contact .contact-inner .form-wrapper {
    width: 55%;
  }
}

@media screen and (max-width: 640px) {
  .contact .contact-inner .form-wrapper {
    width: 100%;
    box-sizing: border-box;
    padding: 1em;
  }
}

.contact .contact-inner .form-wrapper form {
  margin: 0;
}

.contact .contact-inner .form-wrapper form input {
  box-sizing: border-box;
  padding: .5em;
}

.contact .contact-inner .form-wrapper form input,
.contact .contact-inner .form-wrapper form textarea {
  border: 1px lightgrey solid;
  border-radius: 3px;
  font-size: 1em;
  width: 100%;
  margin-bottom: 1em;
}

.contact .contact-inner .form-wrapper form .button {
  width: 50%;
  height: 3em;
  background: #3d691b;
  color: #fff;
  font-size: 1em;
  font-weight: bold;
  border-radius: 8px;
  margin: 0 auto;
  display: block;
  background: #a5a5a5;
}

@media screen and (max-width: 640px) {
  .contact .contact-inner .form-wrapper form .button {
    width: 100%;
  }
}

footer {
  position: relative;
  margin-top: 5.5em;
  width: 100%;
  bottom: 0;
  height: 80px;
  background: #0a1123;
}

footer .copyright {
  position: absolute;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
}

.single {
  width: 60%;
  margin: 5em auto;
}

.single .post-category a {
  color: #000000;
}

.single .post-category i {
  color: #888888;
}

.single {
  width: 60%;
  margin: 5em auto;
  .post-category {
    a {
      color: #000000;
    }
    i {
      color: #888888;
    }
  }
}
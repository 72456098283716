

/////////////// 料金表 ///////////////////////////
.price {
  .price-inner {
    width: 100%;

    > h3 {
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      width: 100%;
      line-height: 1.5em;
      padding: .5em;
      margin: 0 0 1 0;
      border-radius: 3px;
      align-items: center;
      background: $color-four;
      // @include border();
      .fa-color {
        color: #000000;
      }
    }

    .price-tbl {
      width: 100%;
      margin: 1em 0;
      border-collapse: collapse;
      display: none;
      &.open {
        display: inline-table;
      }
      th,
      td {
        border: 1px solid #000000;
        padding: .5em;
        text-align: left;
        @media screen and (max-width: 320px) {
          font-size: .7em;
        }
      }
      td {
        background: #fff;
      }
      th {
        background: rgba(240, 240, 240, 0.863);
        ;
      }
    }
  }
}

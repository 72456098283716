@charset "UTF-8";

/////////////// フッター ///////////////////////////
footer {
  position: relative;
  margin-top: 5.5em;
  width: 100%;
  bottom: 0;
  height: 80px;
  background: $color-primary;
  .copyright {
    position: absolute;
    @include center-xy();
    color: #fff;
    text-align: center;
  }
}